<template>
  <div class="ArticleDiscuss public-studioAdmin-body">
    <!-- 文章评论管理 -->
    <StudioAdminTabs :list="tabs"></StudioAdminTabs>
    <div class="contentBox">
      <!-- 筛选 -->
      <div class="selectTop">
        <div class="lBox public-form-studioAdmin">
          <el-input v-model="form.search_name" placeholder="请输入文章标题" class="input" style="margin-left: 0"></el-input>
          <el-input v-model="form.search_content" placeholder="请输入评论内容" class="input" style="margin-left: 0"></el-input>
          <el-button type="primary" @click="init()">查询</el-button>
          <el-button @click="ClearAll()">重置</el-button>
        </div>
        <div class="rBox">
          <el-button type="primary" plain @click="toMoreDelete()">批量删除</el-button>
        </div>
      </div>
      <!-- 表格 -->
      <div class="tableList">
        <el-table @selection-change="handleSelectionChange" :data="tableData" class="public-table-studioAdmin">
          <el-table-column type="selection" width="55" />
          <el-table-column label="序号" align="center" width="80">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="content" label="评论/回复" align="center" width="180" />
          <el-table-column prop="name" label="评论文章" align="center" width="180" />
          <el-table-column prop="username" label="发表人" align="center" width="180" />
          <el-table-column prop="create_time" label="发表时间" sortable align="center" width="180" />
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" class="text-color-red" @click='toDelete(scope.row.id)'>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block" v-show="total">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="form.page" :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ids: [],
      input: "",
      tableData: [],
      //总数
      total: 0,
      //表单数据
      form: {
        page: 1,
        limit: 10,
        search_content: "",
        search_name: "",
        teaching_studio_id: localStorage.getItem("studioId"),
      },
      tabs: [
        {
          label: "文章评论管理",
          count: 0
        },
      ],
    };
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    this.init();
  },
  computed: {},
  methods: {
    //初始化
    init() {
      this.ArticleDiscuss();
    },
    //多选id
    handleSelectionChange(val) {
      this.ids = val.map((item) => {
        return item.id;
      });
    },

    //重置
    ClearAll() {
      this.form.page = 1;
      this.form.limit = 10;
      this.form.search_content = "";
      this.form.search_name = "";
      this.init();
    },

    //初始化列表
    ArticleDiscuss() {
      this.$axios
        .get("teachingStudioArticleReply/ArticleReply_list", {
          params: this.form,
        })
        .then((res) => {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
        });
    },
    //重置数据
    clearAll() {
      this.form.page = 1;
      this.form.limit = 10;
      this.form.search_content = "";
      this.form.search_name = "";
      this.ArticleDiscuss();
    },
    //删除
    toDelete(id) {
      let arr = [];
      arr.push(id);
      let obj = {
        ids: arr,
        teaching_studio_id: localStorage.getItem("studioId"),
      };
      this.$axios
        .delete("teachingStudioArticleReply/ArticleReply_del", {
          params: obj,
        })
        .then(() => {
          this.$message.success("删除成功");
          this.init();
        });
    },

    //批量删除
    toMoreDelete() {
      let obj = {
        ids: this.ids,
        teaching_studio_id: localStorage.getItem("studioId"),
      };
      this.$axios
        .delete("teachingStudioArticleReply/ArticleReply_del", {
          params: obj,
        })
        .then(() => {
          this.$message.success("删除成功");
          this.init();
        });
    },
    //分页
    handleSizeChange(val) {
      this.form.limit = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.init();
    },
  },
};
</script>
<style lang="less" scoped>
.ArticleDiscuss {
  .navC {
    width: 100%;
    height: 60px;
    padding-left: 26px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    font-weight: bold;
  }

  //内容盒子
  .contentBox {
    padding: 26px 26px;

    .selectTop {
      display: flex;

      .lBox {
        .input {
          width: 186px;
          margin-right: 10px;
        }
      }

      .rBox {
        margin-left: 30px;

        .btn {
          width: 102px;
          height: 34px;
          background: #ff8201;
          border: 1px solid #ff8201;
          border-radius: 4px;
          display: flex;
          align-items: center;
          color: #fff;
        }
      }
    }

    //按钮
    .titleBox {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      .two {
        .btn {
          background: #ff8201;
          border-radius: 4px;
          color: #fff;
          border: 1px solid #ff8201;
        }
      }
    }

    //样式
    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>
